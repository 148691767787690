<template>
    <div class="box">
     <div class="swiper-container">
        <div class="swiper-wrapper">

            <div class="swiper-slide" style="border:1px solid #999">
                <p>1</p>
            </div>
             <div class="swiper-slide" style="border:1px solid #999">
                <p>2</p>
            </div>
             <div class="swiper-slide" style="border:1px solid #999">
                <p>3</p>
            </div>
           
        </div>
        
    </div>
    </div>
</template>

<script>

// import 'swiper/swiper-bundle.css'
import "swiper/dist/css/swiper.min.css";

import Swiper from 'swiper/dist/js/swiper'
export default {
  data () {
    return {
      index: 0
    }
  },
  mounted () {
    document.documentElement.style.fontSize = 750 / 640 * 100 + 'px'
    const mySwiper = new Swiper('.swiper-container', {
      slidesPerView: 'auto',
      centeredSlides: true,
        // slidesPerView: 1,
      spaceBetween: 40,
        breakpointsInverse: true,
      onSlideChangeEnd: function (mySwiper) {
        console.log(mySwiper.activeIndex) // 切换结束时，告诉我现在是第几个slide
      }

    })
    mySwiper.slideTo(2, 1000, false)
    console.log(mySwiper)
  }
}
</script>

<style lang="less" scoped>
*{
    margin: 0;
    padding: 0;
}
.swiper-container{
         touch-action: none;
         box-sizing: border-box;
         width: 100%;
         padding-top: 20%;

}
.swiper-slide {                // 默认设置slide宽度为屏幕的80%
       text-align: center;
       font-size: 18px;
        background: #fff;
        width: 50% !important;
        background-color: skyblue;
        height: 2.666667rem;

     }
    // .swiper-slide:nth-child(2n) {   // 2n偶数行slide宽度为屏幕的60%
    //   width: 60%;
    // }
    // .swiper-slide:nth-child(3n) {  // 3n奇数行slide宽度为屏幕的40%
    //    width: 40%;
    // }
.swiper-slide-active{
    transform: scale(1.3);

}

</style>
